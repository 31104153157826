<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <b-row class="row mt-2">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <h4 class="card-title">
                {{cvCardTitle}}
                </h4>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="float-right">
                  <b-button class="mr-2 mb-2" variant="primary" @click="showAddFirefliesMeeting" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">{{cvbtnAddFirefliesMeeting}}</b-button>
                  <b-button class="mr-2 mb-2" variant="primary" @click="showCreateSession" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">{{cvbtnCreateSession}}</b-button>
                  <b-button class="mr-2 mb-2" variant="primary" @click="gotoMyCounsellingDashboard" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">{{cvbtnMyCounsellingDashboard}}</b-button>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchSPCMFireFliesWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col sm="6" md="3" lg="3" xl="4" class="mb-2">
                <select v-model="whereFilter.counselor_Id" class="form-control"  style="text-transform: capitalize;" @change="setCounselorIdFilter()">
                  <option :value=null>All</option>
                  <option  v-for="(counselor_Id, index) of CounselorId" :key="(index+1)" :value="counselor_Id.counselor_id" >
                    {{counselor_Id.user_name}}
                  </option>
                </select>
              </b-col>
              <b-col sm="12" md="4" lg="5" xl="4" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getSPCMFireFliesDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="spcm_firefliesObjList && spcm_firefliesObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcm_firefliesObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(title)="data">
                    <span v-if="data.item.title">{{data.item.title}}</span><br>
                    <span v-if="data.item.meeting_id" class="mr-2">Id: {{data.item.meeting_id}}</span><br>
                    <a @click="SPCMFireFliesView(data.item)" v-if="!data.item.editable" style=""><i class="fa fa-eye mb-1 text-gideColor pointer" aria-hidden="true" title="View"></i></a>
                    <a @click="SPCMFireFliesSync(data.item)" v-if="!data.item.editable" style=""><i class="fa fa-sync mb-1 ml-2 text-gideColor pointer" aria-hidden="true" title="Sync"></i></a>
                  </template>
                  <template v-slot:cell(created_on)="data">
                   <span>{{data.item.created_on | dateFormat}}</span><br>
                  </template>
                  <template v-slot:cell(meeting_attendees)="data">
                    <div v-if="data.item.meeting_attendees">
                      <span v-for="(attendee,index) of getStudentEmailIds(data.item.meeting_attendees)" :key="index">
                        {{attendee}}<br/>
                        <!-- <i class="fa-solid fa-copy pointer ml-1" title="Copy Email Id" @click="doCopy(attendee)"></i> -->
                        <i class="fa fa-envelope-open pointer" title="Invite user for counselling" @click="openInviteUserModal(data.item.spcmff_id, attendee)"></i>
                      </span>
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <!-- <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col> -->
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Invite User To Counselling Modal -->
    <b-modal
      v-model="showInviteUserModal"
      scrollable
      :title="`Invite User For Counselling`"
      size="xxl"
      id="inviteModal"
    >
      <InviteUser :propProductCode="'spcm'" :propProductName="'Counselor'" :propOpenedInModal="true" :propCounsellor="userData" :propStudent="propStudent" @emitCloseInviteUser="emitCloseInviteUser"/>
      <template #modal-footer="">
        <!-- <b-button size="sm" class="pull-left">
          {{cvbtnModalExpand}}
        </b-button> -->
        <b-button size="sm" @click="closeCreateSession()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showAddFirefliesMeetingModal"
      scrollable
      :title="cvAddModalHeader"
      id="xl"
    >
      <template>
        <form action="#">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label for="validation_id">Fireflies Meeting Id</label>
              <input v-model="fireflies_meeting_id" type="text" class="form-control" required />
            </div>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary" @click="SPCMFireFliesAdd()">Add</button>
          </div>
        </form>
      </template>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="SPCMFireFliesEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSPCMFireFliesEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSPCMFireFliesEdit"
      scrollable
      :title="cvEditModalHeader"
      id="xxl"
    >
      <SPCMFireFliesEdit :propOpenedInModal="true" :propSPCMFireFliesObj="SPCMFireFliesEditObj" @emitCloseSPCMFireFliesEditModal="closeSPCMFireFliesEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="SPCMFireFliesEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSPCMFireFliesEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSPCMFireFliesDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSPCMFireFliesDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="SPCMFireFliesDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSpcmFireFliesView"
      scrollable
      :title="cvViewModalHeader"
      id="xxl"
    >
      <SPCMFirefliesView :propOpenedInModal="true" :propSPCMFireFliesObj="SPCMFireFliesViewObj" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeSPCMFireFliesEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import moment from "moment"
import { SPCMFireFlies } from "../../../FackApi/api/SPCMFireflies"
import SPCMFireFliesEdit from "./SPCMFirefliesEdit.vue"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import InviteUser from "../../../components/InviteUser.vue"
import { SpcmSessions } from "../../../FackApi/api/spcmSession.js"
import SPCMFirefliesView from "./SPCMFirefliesView.vue"

export default {
  name: "SPCMFireFliesList",
  components: {
    SPCMFireFliesEdit,
    InviteUser,
    SPCMFirefliesView
  },
  data () {
    return {
      cvCardTitle: "Fireflies Dashboard",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvAddModalHeader: "Add Fireflies Meeting",
      cvEditModalHeader: "Edit Fireflies",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      cvbtnAddFirefliesMeeting: "Add",
      cvbtnCreateSession: "Invite",
      cvbtnMyCounsellingDashboard: "Counseling Dashboard",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Fireflies",
      showModelSPCMFireFliesEdit: false,
      showModelSPCMFireFliesDelete: false,
      showModelSpcmFireFliesView: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "title", key: "title", class: "text-left align-text-top w-300px", sortable: true },
        { label: "counselor", key: "counselor_name", class: "text-left align-text-top", sortable: true },
        { label: "meeting attendees", key: "meeting_attendees", class: "text-left align-text-top w-150px", sortable: true },
        { label: "Meeting Date", key: "created_on", class: "text-left align-text-top ", sortable: true }
      ],
      spcm_firefliesObjList: [],
      SPCMFireFliesEditObj: null,
      SPCMFireFliesViewObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      gist: {},
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 100,
      showInviteUserModal: false,
      showAddFirefliesMeetingModal: false,
      cvUserEmailLabel: "User Email",
      cvbtnInviteUser: "Invite",
      vmInviteUserFormData: Object.assign({}, {
        "student_email": "",
        "spcmff_id": ""
      }),
      cvCounsellorIdLabel: "Counsellor Id",
      propStudent: null,
      CounselorId: "",
      fireflies_meeting_id: null,
      cvViewModalHeader: ""
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    SPCMFireflies () {
      return this.$store.getters["SPCMFireflies/SPCMFireflies"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  watch: {
    SPCMFireflies (newVal) {
      this.SPCMFireFliesList()
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    this.spcmFirefliesCounselorIds()
    this.setCounselorIdFilter()
    await this.getDateFilter()
    socialvue.index()
    this.SPCMFireFliesList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchSPCMFireFliesWithParams
     */
    async searchSPCMFireFliesWithParams () {
      this.currentBatchNo = 1
      this.SPCMFireFliesList()
    },
    /**
     * Calling Function as per whereFilter
    */
    async getSPCMFireFliesDateWise () {
      this.SPCMFireFliesList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * SPCMFireFliesList
     */
    async SPCMFireFliesList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let SPCMFireFliesListResp = await SPCMFireFlies.SPCMFireFliesList(this, this.whereFilter)
        if (SPCMFireFliesListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.spcm_firefliesObjList = [ ...SPCMFireFliesListResp.resp_data.data ]
          }
          else {
            this.spcm_firefliesObjList = [ ...this.spcm_firefliesObjList, ...SPCMFireFliesListResp.resp_data.data ]
          }

          // Adding the serial numbers:
          this.spcm_firefliesObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.spcm_firefliesObjList.length
        }
        else {
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = SPCMFireFliesListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at SPCMFireFliesList() and Exception:", err)
      }
    },
    /**
     * FirefliesMeeting Add
     */
    async SPCMFireFliesAdd () {
      let spcmFirefliesObj = {
        meetingId: this.fireflies_meeting_id,
        eventType: "Transcription completed"
      }

      await SPCMFireFlies.SPCMFireFliesAdd(this, spcmFirefliesObj)

      this.toastMsg = "Please refresh after few minutes to see the updated list"
      this.toastVariant = "primary"
      this.showToast = true

      this.showAddFirefliesMeetingModal = false

      setTimeout(() => {
        this.SPCMFireFliesList()
      }, 3000)
    },
    /**
     * SPCMFireFliesEdit
     */
    SPCMFireFliesEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/FireFliesEdit/" + this.SPCMFireFliesEditObj.spcmff_id)
        }
        else {
          this.SPCMFireFliesEditObj = item
          this.showModelSPCMFireFliesEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at SPCMFireFliesEdit() and Exception:", err.message)
      }
    },
    /**
     * SPCM FireFlies Edit
     */
    SPCMFireFliesView (item) {
      try {
        this.SPCMFireFliesViewObj = item
        this.showModelSpcmFireFliesView = true
        this.cvViewModalHeader = `${item.title} Detail`
      }
      catch (err) {
        console.error("Exception occurred at SPCMFireFliesView() and Exception:", err.message)
      }
    },
    /**
     * SPCM FireFlies Sync
     */
    async SPCMFireFliesSync (item) {
      let spcmFirefliesObj = {
        spcmff_id: item.spcmff_id,
        meeting_id: item.meeting_id
      }

      await SPCMFireFlies.SPCMFireFliesEdit(this, spcmFirefliesObj)

      this.toastMsg = "Please refresh after few minutes to see the updated information"
      this.toastVariant = "primary"
      this.showToast = true

      setTimeout(() => {
        this.SPCMFireFliesList()
      }, 3000)
    },
    /**
     * emitCloseInviteUser
     * User Invited
     */
    async emitCloseInviteUser (inviteAddResp, slot = null) {
      if (inviteAddResp.resp_status && this.propStudent) {
        // Fireflies session create
        // proceed with creating the session and adding fireflies transcripts as notes
        const sessionCreateObj = {
          student_id: inviteAddResp.resp_data.user.user_id,
          student_email: inviteAddResp.resp_data.user.user_email,
          counsellor_id: inviteAddResp.resp_data.spcm.counsellor_id,
          spcm_id: inviteAddResp.resp_data.spcm.spcm_id,
          spcmff_id: this.vmInviteUserFormData.spcmff_id
        }
        const spcmSessionCreateResp = await SPCMFireFlies.SPCMFireFliesSessionCreate(this, sessionCreateObj)
        if (!spcmSessionCreateResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, spcmSessionCreateResp)
        }
        else {
          this.showInviteUserModal = false
        }
      }
      else {
        // Normal spcm session create
        if (!slot) {
          // Scheduling an appointment time for Counseling Session is not mandatory. If slot is not available, set the counselling appointment to current timeestamp
          slot = {
            spcm_calendar_id: "SPCM" + Math.floor(100000 + Math.random() * 900000), // Generating a random spcm_calendar_id
            slot_start_ts: moment().unix(), // setting the slot start time to current timestamp
            slot_duration: 3600000
          }
        }
        this.showSessionCreationModal = false
        let payload = {
          user_id: this.userData.user_id,
          participant_id: inviteAddResp.resp_data.user.user_id,
          session_title: `Session ${this.spcm_firefliesObjList.length + 1}`,
          spcm_id: inviteAddResp.resp_data.spcm.spcm_id,
          session_start_ts: slot.slot_start_ts,
          session_end_ts: Number(slot.slot_start_ts) + slot.slot_duration,
          counsellor_id: this.userData.user_id,
          spcm_calendar_id: slot.spcm_calendar_id,
          session_state: null,
          source: "spcm" + this.userData.user_id,
          user_email: inviteAddResp.resp_data.user.user_email
        }
        let sessionAddResponse = await SpcmSessions.spcmSessionAdd(this, payload)
        if (sessionAddResponse) {
          this.showInviteUserModal = false
        }
      }
    },
    /**
     * showSPCMFireFliesDeleteDialog
     */
    showSPCMFireFliesDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSPCMFireFliesDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSPCMFireFliesDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * SPCMFireFliesDelete
     */
    async SPCMFireFliesDelete () {
      try {
        let spcmFireFliesDelResp = await SPCMFireFlies.SPCMFireFliesDelete(this, this.delObj.spcmff_id)
        await ApiResponse.responseMessageDisplay(this, spcmFireFliesDelResp)

        if (spcmFireFliesDelResp && !spcmFireFliesDelResp) {
          this.showModelSPCMFireFliesDelete = false
          return false
        }

        let index = this.spcm_firefliesObjList.indexOf(this.delObj)
        this.spcm_firefliesObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSPCMFireFliesDelete = false
      }
      catch (err) {
        console.error("Exception occurred at SPCMFireFliesDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSPCMFireFliesEditModal
     */
    closeSPCMFireFliesEditModal () {
      try {
        this.showModelSPCMFireFliesEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSPCMFireFliesEditModal() and Exception:", err.message)
      }
    },

    /**
     * openInviteUserModal
     */
    openInviteUserModal (spcmffId, userEmail) {
      this.propStudent = {
        student_email: userEmail
      }
      this.vmInviteUserFormData.spcmff_id = spcmffId
      this.showInviteUserModal = true
    },
    /**
     * showCreateSession
     */
    showCreateSession () {
      this.propStudent = null
      this.showInviteUserModal = true
    },
    /**
     * show Add FirefliesMeeting
     */
    showAddFirefliesMeeting () {
      this.propStudent = null
      this.showAddFirefliesMeetingModal = true
    },
    /**
     * gotoMyCounsellingDashboard
     */
    gotoMyCounsellingDashboard () {
      window.open(`spcm_counseling_dashboard`, "_blank")
    },
    /**
     * closeInviteUserModal
     */
    closeInviteUserModal () {
      this.showInviteUserModal = false
    },
    /**
     * Meedting Attendees
    */
    getStudentEmailIds (meetingAttendees) {
      let meetingAttendeesList = JSON.parse(meetingAttendees)
      let StudentList = []
      for (let attendee of meetingAttendeesList) {
        if (!attendee.email.includes("gide.ai") && !attendee.email.includes("highschoolmoms")) {
          StudentList.push(attendee.email)
        }
      }
      return StudentList
    },
    /**
     * SPCMFireFliesCounselorId
    */
    async spcmFirefliesCounselorIds () {
      try {
        let SPCMFireFliesListCounselorIdResp = await SPCMFireFlies.SPCMFireFliesCounselorId(this)
        if (SPCMFireFliesListCounselorIdResp) {
          this.spcm_fireflies_counselor_ids = SPCMFireFliesListCounselorIdResp.resp_data.data
          this.CounselorId = this.spcm_fireflies_counselor_ids
        }
        else {
          this.toastMsg = SPCMFireFliesListCounselorIdResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at spcm_fireflies_counselor_ids() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
    */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * closeCreateSession
     */
    closeCreateSession () {
      this.showInviteUserModal = false
    },
    /**
     *  SPCM Fireflies Counselor Id Filter
    */
    setCounselorIdFilter () {
      this.SPCMFireFliesList()
    }
  }
}
</script>
